import React, {useState} from 'react'
// import {Link} from "react-router-dom";
import { SidebarData } from "./SidebarData";
import * as FaIcons  from "react-icons/fa";

import "../index.css"
// import { gsap } from "gsap";
import logo from "../Assets/img/landing-page/logo-urus.svg"
import AnchorLink from "react-anchor-link-smooth-scroll";

const { useLayoutEffect, useRef } = React;

export default function App() {
    const [sidebar, setSidebar] = useState(false)

    const showSidebar = () => setSidebar(!sidebar)
    const app = useRef();

    useLayoutEffect(() => {

    });


    return (
        <div id="navbaranimation" ref={app} className="page-header z-50 px-5 desktop:px-28 py-5 fixed w-full max-w-screen-2xl flex flex-row items-center justify-between">
            <a className={"top-0 left-0 relative flex"} href='/'>
                <img id="logowhite" className={"top-20 left-20"} style={{}} alt={"logo adiwisista"} src={logo}/>
            </a>

            <div className='flex menu-bars my-auto'>
                <FaIcons.FaBars id={"burger"} className={""} onClick={showSidebar} />
            </div>

            {/* menu nav on larger display  */}
            <div id={"textnav"} className={"hidden text-white text-bold space-x-10 items-center"}>
                <AnchorLink offset={() => 50} href='#hero'>Beranda</AnchorLink>
                <AnchorLink offset={() => 50} href='#sistem'>Fitur</AnchorLink>
                <AnchorLink offset={() => 50} href='/price'>Paket</AnchorLink>
                {/* <AnchorLink offset={() => 50} href='#about'>Tentang Kami</AnchorLink> */}
                <AnchorLink offset={() => 50} href='#contact-us'>Hubungi Kami</AnchorLink>

                <a href={"https://hrweb.hrus.online/"}>
                    <button className={'blue-btn outline outline-2 outline-white hover:outline-sky-200 m-none'}
                            style={{paddingLeft:31, paddingRight:31}}>
                       Login
                    </button>
                </a>
                
            </div>

            <nav className={sidebar ? 'relative nav-menu flex py-5 flex-col active' : 'relative flex flex-col py-5 nav-menu'} style={{zIndex:10000}}>

                <div className={"flex flex-col space-y-8 py-5 px-10 items-center"}>
                    <img id="logonav" className={"top-12 w-32 absolute"} style={{zIndex:"9999999999"}} alt={"logo adiwisista"} src={logo}/>

                    {SidebarData.map((item, index) => {
                        return (
                            <div key={index} className={"w-full mx-auto"}>
                                <div onClick={showSidebar} className={"navbar-item my-auto flex flex-row hover:bg-danai-blue-2 transition duration-300 text-white"}>
                                    <AnchorLink offset={() => 50} className={"text-white text-bold w-full px-5 py-2 h-10 my-auto"} href={item.path}>{item.tittle}</AnchorLink>
                                </div>
                            </div>
                        );
                    })}

                    <a href={"https://hrweb.hrus.online/"}>
                        <button className={'white-btn text-bold m-none text-[var(--hrus-blue)]'}
                                style={{paddingLeft:31, paddingRight:31}}>
                            Login
                        </button>
                    </a>

                    <FaIcons.FaMinus id={"burger"} className={"align-center"} onClick={showSidebar} />
                </div>

            </nav>

        </div>
    );
}

