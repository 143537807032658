import React from 'react'
// import {Link} from "react-router-dom"

import "../index.css"
// import { gsap } from "gsap";
import logo from "../Assets/img/landing-page/logo-urus.svg"

const { useLayoutEffect, useRef } = React;

export default function App() {

    const app = useRef();

    useLayoutEffect(() => {

    });


    return (
        <div id="navbaranimation" ref={app} className="page-header z-50 px-5 desktop:px-28 py-5 fixed w-full max-w-screen-2xl flex flex-row items-center justify-between">
          
            <a className={"top-0 left-0 relative flex"} href='.' rel="noreferrer">
                <img id="logowhite" className={"top-20 left-20"} style={{}} alt={"logo adiwisista"} src={logo}/>
            </a>
            {/* <a href='/'>Home</a> */}

            

        </div>
    );
}

