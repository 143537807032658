
export const SidebarData = [
    {
        tittle:'Beranda',
        path:'#hero',

    },
    {
        tittle:'Fitur',
        path:'#sistem',

    },
    {
        tittle:'Paket',
        path:'#price',

    },
    {
        tittle:'Tentang Kami',
        path:'#about',

    },
    {
        tittle:'Hubungi Kami',
        path:'#contact-us',

    },


]