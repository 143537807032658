import absensi from "../../Assets/img/landing-page/icon-absensi.webp"
import cutiklaim from "../../Assets/img/landing-page/icon-approve reject.webp"
import aktivitas from "../../Assets/img/landing-page/icon-aktivitas.webp"
import shift from "../../Assets/img/landing-page/icon-shift.webp"
import payroll from "../../Assets/img/landing-page/icon-payroll.webp"
import ebp from "../../Assets/img/landing-page/icon-ebp.webp"

import imgdownload from "../../Assets/img/landing-page/img-download.webp"
import hruslogo from "../../Assets/img/landing-page/logo-urus1.svg"
import logooutline from "../../Assets/img/landing-page/logo-urus-outline.svg"
import playstore from "../../Assets/img/landing-page/google-play.png"
import appstore from "../../Assets/img/landing-page/app-store.svg"

import imgPerusahaan from "../../Assets/Images/perusahaan.webp"
import imgKaryawan from "../../Assets/Images/karyawan.webp"
import imgCuti from "../../Assets/Images/cuti.webp"
import imgGaji from "../../Assets/Images/gaji.webp"
import imgKlaim from "../../Assets/Images/klaim.webp"

import client1 from "../../Assets/img/landing-page/IRS LOG.webp"
import client2 from "../../Assets/img/landing-page/PT Fortunindo Buana Sukses.webp"
import client3 from "../../Assets/img/landing-page/Bimble Echlo 1.webp"
import client4 from "../../Assets/img/landing-page/Humana Internasional.webp"
import client5 from "../../Assets/img/landing-page/PT PIM.webp"
import client6 from "../../Assets/img/landing-page/PT Tiga Delta Sekuriti.webp"
import client7 from "../../Assets/img/landing-page/PT Dinamika Nusantara Anugrah.webp"
import client8 from "../../Assets/img/landing-page/PT Andalan Berjaya Nusantara.webp"

import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import '../../index.css'
import React from "react";
import {TimelineMax, TimelineLite} from "gsap/gsap-core";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import Footer from "../Home/footer"

export default function App() {

    gsap.registerPlugin(ScrollTrigger, TimelineMax, TimelineLite)
    const { useLayoutEffect, useRef } = React;
    const comp = useRef();
    const animSection1 = document.getElementById("#sistem")
    const animSection2 = document.getElementById("#section2")
    const animSection3 = document.getElementById("#section3")
    const animSection4 = document.getElementById("#section4")
    const animSection5 = document.getElementById("#section5")


    useLayoutEffect(() => {

        // section1 var
        gsap.set("#imgsection1",{
                translateX:-1000,
                opacity:0,
            })
        gsap.set("#textingsistem",{
                opacity:0,
            })
        function textTLon(){
                const textTL1 = new TimelineMax({ pause: true});
                const imgTL1 = new TimelineMax({ pause: true});

                imgTL1.to('#imgsection1', {
                    translateX:0,
                    duration:0.5,
                    opacity:1,
                })
                textTL1.to('#textingsistem', {duration:0.5, opacity:1,})
            }

        // section2 var
        gsap.set("#section2image",{
                translateX:+1000,
                opacity:0,
            })
        gsap.set("#section2text",{
                opacity:0,
            })
        function section2TLon(){
                const textTL1 = new TimelineMax({ pause: true});
                const imgTL1 = new TimelineMax({ pause: true});

                imgTL1.to('#section2image', {
                    translateX:0,
                    duration:1,
                    opacity:1,
                })
                textTL1.to('#section2text', {duration:2, opacity:1,})
            }

        // section3 var
        gsap.set("#section3text",{
                opacity:0,
            })
        gsap.set(".box",{
                y:-300,
                opacity:0,
            })
        function section3TLon(){
                const textTL1 = new TimelineMax({ pause: true});
                const boxesTL = new TimelineMax({ pause: true});
                const boxes = gsap.utils.toArray('.box');

                boxes.forEach(box => {
                    boxesTL.to(box, {
                        y: 0,
                        opacity:1,
                        duration:0.5
                    })
                });
                textTL1.to('#section3text', {duration:2, opacity:1,})
            }

        // section4 var
        gsap.set("#section4",{
                opacity:0,
            })
        function section4TLon(){
                const textTL1 = new TimelineMax({ pause: true});

                textTL1.to('#section4', {duration:2, opacity:1,})
            }

        // section5 var
        gsap.set("#section5text",{
                opacity:0,
            })
        gsap.set(".boxsection5",{
                translateX:-300,
                opacity:0,
            })
        function section5TLon(){
                const textTL1 = new TimelineMax({ pause: true});
                const boxesTL = new TimelineMax({ pause: true});
                const boxes = gsap.utils.toArray('.boxsection5');

                boxes.forEach(box => {
                    boxesTL.to(box, {
                        translateX: 0,
                        opacity:1,
                        duration:0.5
                    })
                });
                textTL1.to('#section5text', {duration:2, opacity:1,})
            }

        // scrolltrigger
        let ctx = gsap.context(() => {

            // section1
            ScrollTrigger.create({
                    trigger: animSection1,
                    start:"+=1000 bottom",
                    end:"+=1000 top",

                    onEnter: textTLon,

                });

            // section2
            ScrollTrigger.create({
                    trigger: animSection2,
                    start:"+=1300 bottom",
                    end:"+=1300 top",

                    onEnter: section2TLon,
                });

            // section3
            ScrollTrigger.create({
                    trigger: animSection3,
                    start:"+=1700 bottom",
                    end:"+=1700 top",

                    onEnter: section3TLon,
                });

            // section4
            ScrollTrigger.create({
                    trigger: animSection4,
                    start:"+=2300 bottom",
                    end:"+2700 top",

                    onEnter: section4TLon,
                });

            // section5
            ScrollTrigger.create({
                    trigger: animSection5,
                    start:"+=3000 bottom",
                    end:"+3300 top",

                    onEnter: section5TLon,
                });
            }, comp);

        return () => ctx.revert(); // cleanup

    });

    // section feature
    function clickFeature(e) {
        var titleName = e.currentTarget.title;
        var className = e.currentTarget.className;

        // remove current
        e.currentTarget.className = "";
        // add current
        e.currentTarget.className += "btn-active "+ titleName;

        // replace style label in click actvie
        const myElement = document.querySelector('.label-feature-'+titleName);
        myElement.classList.remove('fitur-label');
        myElement.classList.add('fitur-label-active');

        if(titleName == 'perusahaan'){
            deactiveStyle('karyawan');
            deactiveStyle('cuti');
            deactiveStyle('gaji');
            deactiveStyle('klaim');

            var pict = document.getElementById("img-change-feature");
            var valuePict = document.getElementById("img-change-perusahaan").value;

            pict.src = valuePict;

        }
        else if(titleName == 'karyawan'){
            deactiveStyle('perusahaan');
            deactiveStyle('cuti');
            deactiveStyle('gaji');
            deactiveStyle('klaim');

            var pict = document.getElementById("img-change-feature");
            var valuePict = document.getElementById("img-change-karyawan").value;

            pict.src = valuePict;
        }
        else if(titleName == 'cuti'){
            deactiveStyle('karyawan');
            deactiveStyle('perusahaan');
            deactiveStyle('gaji');
            deactiveStyle('klaim');

            var pict = document.getElementById("img-change-feature");
            var valuePict = document.getElementById("img-change-cuti").value;

            pict.src = valuePict;
        }
        else if(titleName == 'gaji'){
            deactiveStyle('karyawan');
            deactiveStyle('perusahaan');
            deactiveStyle('cuti');
            deactiveStyle('klaim');

            var pict = document.getElementById("img-change-feature");
            var valuePict = document.getElementById("img-change-gaji").value;

            pict.src = valuePict;
        }
        else if(titleName == 'klaim'){
            deactiveStyle('karyawan');
            deactiveStyle('perusahaan');
            deactiveStyle('cuti');
            deactiveStyle('gaji');

            var pict = document.getElementById("img-change-feature");
            var valuePict = document.getElementById("img-change-klaim").value;

            pict.src = valuePict;
        }
    }

    // function deactive style feature
    function deactiveStyle(titleName){
        // replace style box deactive
        const elementBox = document.querySelector('.btn-active.'+titleName);
        if(elementBox != null){
            elementBox.classList.remove('btn-active');
            elementBox.classList.add('btn-nonactive');
        }

        // replace style label deactive
        const elementLabel = document.querySelector('.label-feature-'+titleName);
        if(elementLabel != null){
            elementLabel.classList.remove('fitur-label-active');
            elementLabel.classList.add('fitur-label');
        }
    }

    // carousel feature in small view 
    

    return (
        
        <div className={"section1 mt-14"}>
            
            {/* SISTEM START  */}
            <div id={"sistem"} className="z-10 w-full h-fit flex justify-center mb-44 px-8 desktop:px-28">
                <div className={"sistem-background"}>
                    <div className={"sistem-wrapper flex flex-col gap-10 desktop:gap-24"}>
                        <div className={"sistem-title"}>
                            <h1 id="textingsistem" className={"text-h1 text-xl small:leading-none"}>
                                Sistem yang Powerful dan Terintegrasi
                            </h1>
                        </div>
                        <div className={"sistem-step grid grid-cols-1 mobile:grid-cols-2 tablet:grid-cols-3 w-full px-0 desktop:px-4"}>
                            <div className={"sistem-item"}>
                                <img src={absensi} className={"w-fit"} alt="hr"></img>
                                <h2 className={"text-h2 text-xl desktop:text-2xl"}>Absensi yang Terkoneksi</h2>
                                <p>
                                Hadirkan efisiensi dan akurasi dalam pencatatan kehadiran karyawan dengan Absensi, solusi modern untuk manajemen waktu karyawan.
                                </p>
                            </div>
                            <div className={"sistem-item"}>
                                <img src={aktivitas} className={"w-fit"} alt="ebp"></img>
                                <h2 className={"text-h2 text-xl desktop:text-2xl"}>Aktivitas Karyawan yang Terintegrasi</h2>
                                <p>
                                Dapatkan pemahaman menyeluruh tentang kinerja dan produktivitas tim Anda melalui Aktivitas Karyawan, lacak aktivitas karyawan setiap hari.
                                </p>
                            </div>
                            <div className={"sistem-item"}>
                                <img src={cutiklaim} className={"w-fit"} alt="ebp"></img>
                                <h2 className={"text-h2 text-xl desktop:text-2xl"}>Cuti, Klaim & Lembur yang Efisien</h2>
                                <p>
                                Mengatasi tumpukan administrasi dengan Cuti, Klaim & Lembur yang terintegrasi, memastikan proses yang lancar dan pengelolaan yang tepat untuk manfaat karyawan yang lebih baik.
                                </p>
                            </div>
                            <div className={"sistem-item"}>
                                <img src={shift} className={"w-fit"} alt="ebp"></img>
                                <h2 className={"text-h2 text-xl desktop:text-2xl"}>Atur Shift Kerja dengan Mudah</h2>
                                <p>
                                Dengan Atur Shift Kerja, manajemen jadwal menjadi lebih sederhana dan terorganisir, memungkinkan pengaturan shift yang fleksibel dan pengalaman kerja yang seimbang.
                                </p>
                            </div>
                            <div className={"sistem-item"}>
                                <img src={payroll} className={"w-fit"} alt="payroll"></img>
                                <h2 className={"text-h2 text-xl desktop:text-2xl"}>Payroll yang Tepat Waktu</h2>
                                <p>
                                Simplifikasi proses penggajian dengan Payroll, memastikan penghitungan gaji yang akurat dan tepat waktu, meningkatkan kepuasan karyawan dan efisiensi pengelolaan sumber daya manusia.
                                </p>
                            </div>
                            <div className={"sistem-item"}>
                                <img src={ebp} className={"w-fit"} alt="ebp"></img>
                                <h2 className={"text-h2 text-xl desktop:text-2xl"}>Employee Benefit yang Menginspirasi</h2>
                                <p>
                                Memperkuat kesejahteraan karyawan dengan Employee Benefit, menawarkan program dan manfaat yang komprehensif, menciptakan lingkungan kerja yang memotivasi dan produktif.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* SISTEM END  */}


            {/* DOWNLOAD APP  */}

            <div id={"downloadapps"} className={"px-8 small:mb-44"}>
                <div className={"downloadapps-background flex justify-center"}>
                    <div className={"downloadapps-wrapper"}>
                        <div id={"imgdownload"} className={"flex flex-1/2 justify-end gap-0"}>
                            <img src={imgdownload} alt="mobilehrus" className={"w-full"} />
                        </div>
                        <div id={"textingdownload"} className={"flex flex-col justify-center gap-5 px-10 mobile:px-6 pt-4 desktop:pr-20"}>
                            <h2 className={"w-full h-fit flex flex-col mobile:flex-row items-center desktop:items-baseline text-h2 mobile:text-xl text-2xl tablet:text-3xl"}>
                                Download Aplikasi <img src={hruslogo} alt="hruslogo" className={"ml-2 w-[40%] mobile:w-[20%] desktop:h-fit"} />
                            </h2>
                            <div className={"flex flex-1/2 flex-col mobile:flex-row gap-2 desktop:gap-5 items-center"}>
                                <a className={"w-32 mobile:w-30 desktop:w-44 desktop:h-fit"} href='https://play.google.com/store/apps/details?id=com.adiwisista.urus' target="_blank" rel="noreferrer">
                                    <img  src={playstore} alt="playstore"/>
                                </a>
                                <a className={"w-32 mobile:w-30 desktop:w-40 desktop:h-fit align-center"} href='https://apps.apple.com/app/urus/id1548258905' target="_blank" rel="noreferrer">
                                    <img src={appstore} alt="appstore"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>


            {/* FITUR START  */}

            <div id={"fitur"} >
                <div className={"fitur-background"}>
                    <div className={"fitur-wrapper flex flex-col gap-24 py-32"}>
                        <div className={"fitur-title text-center"}>
                            <h1 id={"textingfitur"} className={"text-h1 text-xl small:leading-none"}>Fitur yang Anda butuhkan ada dalam satu platform</h1>
                        </div>

                        <div className={"fitur-content flex flex-row justify-between pl-28"}>
                            <div className={"tab-fitur grid grid-flow-row gap-3"}>
                                <button id={"btn-feature"} title={"perusahaan"} href={"#"} className={"btn-active perusahaan"} onClick={clickFeature}>
                                    <div id={"textingfitur"} className={"fitur-item flex-1"}>
                                        <h1 className={"text-3xl label-feature-perusahaan fitur-label-active"}>Perusahaan</h1>
                                        <label>Atur Profile Perusahaan dan Peranan</label>
                                    </div>
                                </button>
                                <button id={"btn-feature"} title={"karyawan"} href={"#"} className={"btn-nonactive karyawan"} onClick={clickFeature}>
                                    <div id={"textingfitur"} className={"fitur-item flex-1"}>
                                        <h1 className={"text-3xl label-feature-karyawan fitur-label"}>Karyawan</h1>
                                        <label>Atur Profil Karyawan, Jam Kerja dan absensi via mobile app</label>
                                    </div>
                                </button>
                                <button id={"btn-feature"} title={"cuti"} href={"#"} className={"btn-nonactive cuti"} onClick={clickFeature}>
                                    <div id={"textingfitur"} className={"fitur-item flex-1"}>
                                        <h1 className={"text-3xl label-feature-cuti fitur-label"}>Cuti</h1>
                                        <label>Atur Hak dan Jenis Cuti melalui web, dan Pengajuan Cuti via mobile app</label>
                                    </div>
                                </button>
                                <button id={"btn-feature"} title={"klaim"} href={"#"} className={"btn-nonactive klaim"} onClick={clickFeature}>
                                    <div id={"textingfitur"} className={"fitur-item flex-1"}>
                                        <h1 className={"text-3xl label-feature-klaim fitur-label"}>Klaim</h1>
                                        <label>Atur pengajuan dan penyetujuan klaim/reimburse</label>
                                    </div>
                                </button>
                                <button id={"btn-feature"} title={"gaji"} href={"#"} className={"btn-nonactive gaji"} onClick={clickFeature}>
                                    <div id={"textingfitur"} className={"fitur-item flex-1"}>
                                        <h1 className={"text-3xl label-feature-gaji fitur-label"}>Gaji</h1>
                                        <label>Penghitungan Payroll, PPH 21, iuran BPJS, lembur, tunjangan lainnya dan unduh slip gaji dari mobile app</label>
                                    </div>
                                </button>
                            </div>

                            <div id={"imgfitur"}>
                                <input type={"hidden"} id={"img-change-perusahaan"} value={imgPerusahaan}/>
                                <input type={"hidden"} id={"img-change-karyawan"} value={imgKaryawan}/>
                                <input type={"hidden"} id={"img-change-cuti"} value={imgCuti}/>
                                <input type={"hidden"} id={"img-change-gaji"} value={imgGaji}/>
                                <input type={"hidden"} id={"img-change-klaim"} value={imgKlaim}/>

                                <img id={"img-change-feature"} src={imgPerusahaan} alt="fitur" width={"full"}/>
                            </div>
                        </div>
                    </div>

                    {/* for responsive small layout   */}
                    <div className={"fitur-wrapper-small flex flex-col gap-8 py-12 px-8"}>
                        <div className={"fitur-title text-center"}>
                            <h1 id={"textingfitur"} className={"text-h1 text-xl small:leading-none"}>Fitur yang Anda butuhkan ada dalam satu platform</h1>
                        </div>

                        <div className={"fitur-content text-center"}>
                            <Slide slidesToScroll={1} slidesToShow={1} indicators={true}>
                                <div className="each-slide-effect">
                                    <div title={"perusahaan"} >
                                        <img className={"w-fit mobile:w-[75%]"} src={imgPerusahaan} alt={"perusahaan"}/>
                                        <div id={"textingfitur"} className={"fitur-item flex-1"}>
                                            <h2 className={"text-2xl tablet:text-3xl fitur-label-active"}>Perusahaan</h2>
                                            <label>Atur Profile Perusahaan dan Peranan</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="each-slide-effect">
                                    <div title={"karyawan"} >
                                        <img className={"w-fit mobile:w-[75%]"} src={imgKaryawan} alt={"karyawan"}/>
                                        <div id={"textingfitur"} className={"fitur-item flex-1"}>
                                            <h2 className={"text-2xl tablet:text-3xl fitur-label-active"}>Karyawan</h2>
                                            <label>Atur Profil Karyawan, Jam Kerja dan absensi via mobile app</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="each-slide-effect">
                                    <div title={"cuti"} >
                                        <img className={"w-fit mobile:w-[75%]"} src={imgCuti} alt={"cuti"}/>
                                        <div id={"textingfitur"} className={"fitur-item flex-1"}>
                                            <h2 className={"text-2xl tablet:text-3xl fitur-label-active"}>Cuti</h2>
                                            <label>Atur Hak dan Jenis Cuti melalui web, dan Pengajuan Cuti via mobile app</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="each-slide-effect">
                                    <div title={"klaim"} >
                                        <img className={"w-fit mobile:w-[75%]"} src={imgKlaim} alt={"klaim"}/>
                                        <div id={"textingfitur"} className={"fitur-item flex-1"}>
                                            <h2 className={"text-2xl tablet:text-3xl fitur-label-active"}>Klaim</h2>
                                            <label>Atur pengajuan dan penyetujuan klaim/reimburse</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="each-slide-effect">
                                    <div title={"gaji"} >
                                        <img className={"w-fit mobile:w-[75%]"} src={imgGaji} alt={"gaji"}/>
                                        <div id={"textingfitur"} className={"fitur-item flex-1"}>
                                            <h2 className={"text-2xl tablet:text-3xl fitur-label-active"}>Gaji</h2>
                                            <label>Template payroll, atur PPH 21 dan iuran BPJS TK & Kesehatan, atur Slip Gaji via mobile app</label>
                                        </div>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        
                    </div>
                </div>
            </div>

            {/* PRICE START  */}
            <div id={"price"} className={""}>
                <div className={"price-background"}>
                    <div className={"price-wrapper desktop:flex-row px-8 desktop:px-20 small:gap-8"}>
                        <div id={"textingprice"} className={"flex flex-col desktop:w-2/5 justify-center"}>
                            <h2 className={"text-h2 text-3xl text-bold"}>Kebebasan Tanpa Batas!</h2>
                            <br/>
                            <p className="text-xl desktop:text-2xl">Nikmati pilihan paket <span className={"text-xl desktop:text-2xl text-green"}>Basic</span> dan <span className={"text-xl desktop:text-2xl text-blue"}>Ultima</span> dengan harga terjangkau untuk solusi yang dapat digunakan tanpa batasan <em className={"text-xl desktop:text-2xl"}>user</em></p>
                            <br/>
                            <a className={"blue-btn text-bold text-lg"} href="mailto:customer.care@hrus.online?subject=Request Demo HRUS&body=Halo admin, saya ingin mengajukan request demo layanan HRUS." target="_blank" rel="noreferrer" > Request Demo!</a>
                           
                        </div>
                        <div id={"cardprice"} className={"flex desktop:w-3/5"}>
                            <div className={"c1 desktop:flex-1"}>
                                <div className={"card flex-1 space-y-4 tablet:space-y-5"}>
                                    <div className={"cardpart"}>
                                        <h2 className={"text-h2 text-2xl desktop:text-3xl text-bold"}>Basic</h2>
                                        <p>Solusi payroll terintegrasi dengan fitur komprehensif.</p>
                                        <p></p>
                                        <p id={"caption"}>FREE TRIAL 14 HARI</p>
                                    </div>
                                    <hr/>
                                    <div className={"cardpart"}>
                                        <h3 className={"text-3xl desktop:text-4xl text-h2"}>Rp 299.000 <span className={"text-lg desktop:text-xl font-normal"}>/bulan</span></h3>
                                        <p className={"text-lg desktop:text-xl text-h2"}>Rp 3.588.000 <span className={"text-lg desktop:text-xl font-normal"}>/tahun</span></p>
                                    </div>
                                    <hr/>
                                    <ul className={"price-item"}>
                                        <li>Manajemen Karyawan & Lacak Aktivitas Kerja Karyawan</li>
                                        <li>Manajemen Gaji, Pengelolaan BPJS & PPH 21</li>
                                        <li>Manajemen Absensi Harian, Mingguan & Bulanan</li>
                                        <li>Employee Benefit</li>
                                    </ul>
                                    <button className={"white-btn text-bold btn-outline"} style={{marginTop:50}} href="https://hrus.online/urus-web-hr/auth/registrasi" target="_blank" rel="noreferrer"> Pilih Paket</button>
                                </div>
                            </div>
                            
                            <div className={"c2 desktop:flex-1"}>
                                <div className={"topcard"}>
                                    <h3>BEST VALUE</h3>
                                </div>
                                <div className={"card space-y-4 tablet:space-y-5 float-left"}>
                                    <div className={"cardpart"}>
                                        <h2 className={"text-blue text-h2 text-2xl desktop:text-3xl text-bold"}>Ultima</h2>
                                        <p>Solusi payroll terintegrasi dengan fitur komprehensif.</p>
                                        <p></p>
                                        <p id={"caption"}>FREE TRIAL 14 HARI</p>
                                    </div>
                                    <hr/>
                                    <div className={"cardpart"}>
                                        <h3 className={"text-3xl desktop:text-4xl text-h2"}>Rp 599.000 <span className={"text-lg desktop:text-xl font-normal"}>/bulan</span></h3>
                                        <p className={"text-lg desktop:text-xl text-h2"}>Rp 7.188.000 <span className={"text-lg desktop:text-xl font-normal"}>/tahun</span></p>
                                    </div>
                                    <hr/>
                                    <ul className={"price-item"}>
                                        <li>Manajemen Karyawan & Lacak Aktivitas Kerja Karyawan</li>
                                        <li>Manajemen Gaji, Pengelolaan BPJS & PPH 21</li>
                                        <li>Manajemen Absensi Harian, Mingguan & Bulanan</li>
                                        <li>Manajemen Cuti, Klaim & Lembur</li>
                                        <li>Multi Lokasi & Cabang Perusahaan</li>
                                        <li>Employee Benefit</li>
                                    </ul>
                                    <div className={"flex bg-sky-100 p-3 justify-center"}>
                                        Termasuk semua fitur Basic!
                                    </div>
                                    <button className={"blue-btn text-bold btn-outline"} style={{marginTop:50}} href="https://hrus.online/urus-web-hr/auth/registrasi" target="_blank" rel="noreferrer">Pilih Paket</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* SECTION START  */}
            <div id={"about"}>
                <div className={"about-background"}>
                    <div className={"about-wrapper px-8 desktop:px-28 py-20 gap-20"}>
                        <div className={"textingabout small:w-full w-4/6"}>
                            <h1 className={"text-h1-white text-4xl desktop:text-6xl"}>Human Resources</h1>
                            <h1 className={"text-h1-white text-4xl desktop:text-6xl mb-6"}>Utility Solutions (HRUS)</h1>
                            <img className={"small:max-w-[70%] mobile:max-w-[45%] w-cover"} src={logooutline} alt={"logo"}/>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* CLIENT START */}
            <div id={"client"} className={"z-10 w-full h-fit flex justify-center my-32 desktop:my-44 px-8 desktop:px-28"}>
                <div className={"client-background"}>
                    <div className={"client-wrapper flex flex-col gap-24"}>
                        <h1 className={"client-title text-h1 text-xl small:leading-none"}
                        >Telah Dipercaya Membantu Manajemen Karyawan dengan Optimal</h1>
                        <div className={"client-list grid grid-cols-2 tablet:grid-cols-3 desktop:grid-cols-4 w-full px-4 justify-items-center items-center"}>
                            <img src={client1} className={"client-logo"} alt="IRS Log"/>
                            <img src={client2} className={"client-logo"} alt="Fortunindo Buana Sukses"/>
                            <img src={client3} className={"client-logo"} alt="Bimbel Echlo"/>
                            <img src={client4} className={"client-logo"} alt="Humana International"/>
                            <img src={client5} className={"client-logo"} alt="PT. PIM"/>
                            <img src={client6} className={"client-logo"} alt="Tiga Delta Sekuriti"/>
                            <img src={client7} className={"client-logo"} alt="PT DNA"/>
                            <img src={client8} className={"client-logo"} alt="PT ABN"/>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
