import React from "react";
import heroAsset from "../../Assets/img/landing-page/hero-img.webp"
import Navbar from "../../Components/Navbar"


const { useRef } = React;

export default function App() {
    const app = useRef();
    return (
        <div id={"hero"} ref={app} className="App relative w-full">
            <Navbar />

            <div className={"flex flex-col-reverse desktop:flex-row relative w-full h-full items-start px-8 desktop:px-14 gap-8 tablet:gap-0 my-28 top-0 desktop:top-20"}>
                <div className={"z-20 hero-message-wrapper landing-page flex flex-col flex-1 gap-2 desktop:gap-3 desktop:px-3"}>
                    <h1 className={"text-3xl tablet:text-4xl desktop:text-5xl font-semibold "}>
                    Maksimalkan Potensi HRIS dengan Sentuhan Kemudahan Tersendiri
                    </h1>
                    <p>
                    HRUS merupakan inovasi terkini dalam dunia manajemen sumber daya manusia, yang didesain khusus untuk memenuhi kebutuhan Perusahaan Kecil dan Menengah. Dengan konsep berbasis cloud, HRUS menyediakan solusi lengkap untuk administrasi penggajian karyawan. Selain itu, HRUS juga menyediakan sistem manajemen HR yang terintegrasi, memungkinkan pencatatan absensi yang akurat, pengajuan dan persetujuan cuti, klaim, dan lembur yang efisien.
                    </p>
                    <p>
                    Dengan HRUS, mengelola aspek-aspek penting dalam administrasi sumber daya manusia menjadi lebih terintegrasi. Solusi ini membawa kemudahan dan kenyamanan bagi perusahaan, meningkatkan produktivitas serta memberikan pengalaman kerja yang lebih baik bagi karyawan.
                    </p>
                    <a className={"white-btn text-bold text-lg"} href="https://hrweb.hrus.online/auth/registrasi.xhtml" target="_blank" rel="noreferrer"> Daftar Sekarang</a>
                </div>

                <div className={"z-20 hero-message-wrapper landing-page flex flex-col desktop:flex-1"}>
                    <img alt={"heroAsset"} className={"w-full small:max-w-sm mobile:max-w-xl tablet:max-w-md desktop:max-w-full"} src={heroAsset}></img>
                </div>
            </div>
        </div>
    );
}

