import Home from "./Home/Header";
import Section1 from "./Home/section1"

import Footer from "./Home/footer"
import React from "react";
import Privacy from "./privacy";


export default function App() {

    return (
        <div id="home" className={"flex overflow-x-hidden flex-col w-full mx-auto"} >
            <div className={"-z-10 absolute inset-0 w-full h-[13.5%] desktop:h-screen hero-background"}>
            </div>

            <Home/>
            <Section1/>
            {/* <Footer/> */}

        </div>
    );
}


