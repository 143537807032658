import React from "react";
// import { gsap } from "gsap";
// import Privacy from "../privacy"

import email from "../../Assets/img/landing-page/mail.svg"
import phone from "../../Assets/img/landing-page/phone.svg"
import address from "../../Assets/img/landing-page/map-pin.svg"
import top from "../../Assets/img/landing-page/chevron-up.svg"
import kominfo from "../../Assets/img/landing-page/terdaftar-kominfo.webp"

// import { Link, Outlet } from "react-router-dom";
import { Link } from 'react-router-dom';

export default function App() {
        const handleClickScroll = () => {
            const element = document.getElementById('hero');
            if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            }
        };

        return (
            
            <div id="contact-us" className="mt-20">
                <div className={"contact-us-background"}>
                    <div className={"contact-us-wrapper place-content-between small:gap-8"}>
                        <div id={"hubungikami"} className={"flex flex-col gap-3"}>
                            <h1 className={"text-h1"}>Hubungi Kami</h1>
                            <div className={"info flex flex-col tablet:flex-row small:gap-3 desktop:gap-12"}>
                                <div className={"info-content"}>
                                    <img src={email} alt="email"></img>
                                    <a href={"mailto:customer.care@hrus.online"}>customer.care@hrus.online</a>
                                    
                                </div>
                                <div className={"info-content"}>
                                    <img src={phone} alt="phone"></img>
                                    <p>021-5010 3360</p>
                                </div>
                                <div className={"info-content"}>
                                    <img src={address} alt="address" />
                                    <p className={"flex leading-1 small:text-center"}>Prosperity Tower Lantai 20
                                    <br/>District 8 SCBD Lot 28
                                    <br/>Jl Jendral Sudirman Kav 52-53
                                    <br/>Jakarta 12190</p>
                                </div>
                            </div>
                        </div>
                        <div id={"tentangkami"} className={"flex flex-col gap-3"}>
                            <h1 className={"text-h1"}>Tentang Kami</h1>
                            <div className={"info-content"}>
                                
                                <ul>
                                    <li>
                                        <Link to="/privacy" target={"_blank"}>Kebijakan dan Privasi</Link>
                                      
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div id={"lisence"} className={"flex flex-col gap-3 w-fit"}>
                            <a className={""} href='https://pse.kominfo.go.id/tdpse-detail/295' target="_blank" rel="noreferrer">
                                <img src={kominfo} alt="kominfo"/>
                            </a>
                            
                        </div>
                    </div>
                    <div className={"footer-btn"}>
                        <button id={"top"} className={"flex flex-row dark-btn text-bold gap-3 items-center"} onClick={handleClickScroll} >
                            <img src={top} alt="back2top"/>
                            Back to Top
                        </button>
                    </div>    
                    <hr />
                    <div className={"copyright flex flex-row justify-center"}>
                        <p>© Copyright 2022 PT Adiwisista Solusi Awani</p>
                    </div>
                </div>

            </div>

        );
}

