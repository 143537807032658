import './index.css';
import Home from "./Pages/Home";
import Footer from "./Pages/Home/footer";
import Privacy from "./Pages/privacy"
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import React, {useState} from "react";
import {TimelineMax, TimelineLite} from "gsap/gsap-core";
import { Route, Routes } from "react-router-dom";

function App() {
    gsap.registerPlugin(ScrollTrigger, TimelineMax, TimelineLite)
    const { useLayoutEffect, useRef } = React;
    const comp = useRef();
    const [ctx] = useState(true);

    useLayoutEffect(() => {

        let ctx = gsap.context(() => {

            gsap.set('#logo', {
                display:"none",
                autoAlpha:"0",
                duration:0.1,
            })
            gsap.set('#logo', {
                display:"block",
                autoAlpha:"1",
                duration:0.1,
            })

            function navOn(){
                const imgTL1 = new TimelineMax({ pause: true});

                // imgTL1.to('#logowhite',{
                //     display:"none",
                //     autoAlpha:"0",
                //     duration:0.1,
                //     ease: "none",
                // })

                imgTL1.to('#navbaranimation', {
                    // backgroundImage:"url('img/landing-page/background@2x.png')",
                    background:"transparent",
                    boxShadow:"0px 4px 10px rgba(0, 0, 0, 0.25)",
                    duration:0.5,
                    ease: "none",
                })

                // imgTL1.to('#logo',{
                //     width:"70px",
                //     display:"block",
                //     autoAlpha:"1",
                //     duration:0.1,
                //     ease: "none",
                // })

                imgTL1.to('#textnav', {
                    duration:0.5,
                    ease: "none",
                    color:"#707070"
                })

                imgTL1.to('#burger',{
                    color:"#A1CC3A",
                    duration:0.5,
                    ease: "none",
                })
            }
            function navOff(){
                const textTL1 = new TimelineMax({ pause: true});
                const imgTL1 = new TimelineMax({ pause: true});

                imgTL1.to('#navbaranimation', {
                    background:"transparent",
                    duration:0.5,
                    ease: "none",
                    boxShadow:"0px 4px 10px rgba(0, 0, 0, 0)"
                })

                gsap.to('#burger',{
                    color:"#ffffff",
                    duration:0.5,
                    ease: "none",
                })

                gsap.to('#logo',{
                    autoAlpha:"0",
                    display:"none",
                    duration:0.5,
                    ease: "none",
                })

                textTL1.to('#textnav', {
                    duration:0.5,
                    ease: "none",
                    color:"#ffffff"
                })
            }


            //navigasi
            ScrollTrigger.create({
                trigger: "#header",
                start:"+=150 bottom",
                end:"+250 top",
                onEnter: navOff,
                onEnterBack: navOff,
                onLeave: navOn,
                onLeaveBack: navOn,
            });

        }, comp);

        return () => ctx.revert(); // cleanup

    }, [ctx]);
  return (
      <div className={"h-full relative flex flex-col max-w-screen-2xl mx-auto"} >
          {/* <Navbar/> */}
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/privacy" element={<Privacy />} />
            </Routes>
          {/* <Footer/> */}
      </div>
  );
}

export default App;