import React from "react";
import Navbar2 from "../Components/Navbar-2"
import Footer2 from "./Home/footer-2"

// const { useRef } = React;

const Privacy = () => {
    // const comp = useRef();

    return (
        <div className={""}>
            <Navbar2 />
            <div className={"content relative full my-28 desktop:top-20 px-8 desktop:px-28 "}>
                <div className={"desc-privacy mb-16"}>
                    <h1 className={"text-h1 text-3xl tablet:text-4xl desktop:text-5xl font-semibold text-center"}>
                        Kebijakan Privasi Penggunaan Layanan HRUS
                    </h1>
                    <br/><br/>

                    <div class="value-span">
                        <span>
                            PT Adiwisista Solusi Awani membuat kebijakan privasi untuk melindungi privasi Anda.
                            Sebagai Penyedia Layanan, Kami menjaga kerahasiaan dan keamanan data Anda.
                            Kami menyimpan dan mengelola berbagai informasi pribadi, organisasi dan keuangan Anda untuk tujuan pengelolaan <i>human resource</i>, mencatat absensi, mengelola pengajuan sakit, izin dan cuti karyawan, penggajian, penghitungan pajak PPh 21 dan BPJS Anda hingga <i>employee benefits</i>.
                        </span>
                    </div>
                </div>

                <div className={"value-desc flex flex-col gap-3"}>
                    <h2 className={"text-h2 text-xl desktop:text-2xl"}>
                        Informasi yang Kami Kumpulkan &amp; Gunakan
                    </h2>

                    <div class="value-span">
                        <span>
                            Informasi yang Kami kumpulkan meliputi informasi terkait perusahaan Anda, informasi terkait karyawan Anda dan informasi terkait aktivitas Anda dalam menggunakan Layanan.
                            Informasi dan/atau Data Anda hanya digunakan untuk kepentingan penggunaan Layanan HRUS ini.
                            Dengan menggunakan Layanan ini, Anda menjamin bahwa Anda bertanggung jawab untuk mendapatkan persetujuan dari karyawan Anda bahwa informasi dan/atau data pribadi milik karyawan Anda Kami kumpulkan dan kelola dengan baik dan terjaga kerahasiaannya.
                        </span>
                    </div>

                    <br/><br/>

                    <h2 className={"text-h2 text-xl desktop:text-2xl"}>
                        Penggunaan&nbsp;<em className={"text-xl desktop:text-2xl"}>Cookies</em>&nbsp;dan Statistik Situs
                    </h2>

                    <div class="value-span">
                        <span>
                            Kami menggunakan sarana untuk meningkatkan pengalaman pengguna Situs dan melacak pengguna Situs, termasuk&nbsp;<i>cookies</i>.
                            <i>Cookies</i> adalah bagian-bagian kecil dari teks yang suatu situs tempatkan pada komputer Anda untuk membantu mengingat informasi mengenai kunjungan Anda.
                            <i>Cookies</i> tidak dapat membaca data yang ada pada perangkat keras Komputer Anda atau mengambil informasi pribadi Anda.
                            Kami menggunakan informasi yang diperoleh dari cookies untuk meningkatkan pengalaman penggunaan Anda dan kualitas Layanan kami secara keseluruhan.
                            <i>Cookies</i> kami dapat juga disediakan oleh penyedia Layanan pihak ketiga yang memiliki izin untuk meletakkan sarana cookies pada Situs kami.
                            Kami mengumpulkan statistik pengunjung untuk mengukur kinerja, keamanan, dan pembelajaran untuk perbaikan Situs.
                            Statistik pengunjung berisi ringkasan lalu lintas ke Situs kami.
                            Contoh data yang Kami peroleh melalui statistik pengunjung adalah&nbsp;<i>number of pageviews</i> (jumlah halaman yang diakses), <i>number of visits</i> (jumlah kunjungan ke Situs), jumlah data yang berpindah, dan lain-lain.
                        </span>
                    </div>


                    <br/><br/>

                    <h2 className={"text-h2 text-xl desktop:text-2xl"}>
                        Mengubah atau Menghapus Data Anda
                    </h2>

                    <div class="value-span">
                        <span>
                            Semua pengguna Layanan HRUS dapat meninjau, memperbaharui atau memperbaiki informasi pribadi yang terdapat pada Situs dan Aplikasi Kami.
                            Akun HRUS tidak dapat dihapus seketika setelah Masa Layanan Pengguna berakhir atau ketika Pengguna memutuskan mengakhiri penggunaan.
                            Penghapusan akun HRUS secara langsung saat Masa Layanan berakhir tidak dimungkinkan untuk dilakukan demi menjaga agar data tetap aman serta menjaga stabilitas Layanan Kami.
                            Apabila Masa Layanan HRUS&nbsp; berakhir, maka data akan otomatis tidak dapat diakses oleh pengguna.
                            Data Anda akan dihapus dari <i>database</i> Kami setelah 79 (tujuh puluh sembilan) hari kalender dari berakhirnya Masa Layanan apabila Masa Layanan tidak diperpanjang.
                        </span>
                    </div>


                    <br/><br/>

                    <h2 className={"text-h2 text-xl desktop:text-2xl"}>
                        Kerahasiaan
                    </h2>

                    <div class="value-span">
                        <span>
                            Situs website dan aplikasi dilengkapi dengan protokol keamanan data&nbsp;<i>Security Socket Layer</i> (SSL).
                            Kami maupun Anda dapat mengakses data dan informasi pribadi karyawan Anda.
                            Kita bersama harus menjaga dan memastikan untuk menjaga kerahasiaan data, dokumen-dokumen dan/atau informasi-informasi yang tersedia selama penggunaan Layanan ini baik informasi tersebut bersumber dari Anda maupun dari Layanan Kami.
                            Setiap pengungkapan kerahasiaan yang akan dilakukan oleh Kami atau Anda harus mendapat dan mengajukan persetujuan tertulis dari pihak yang mengungkapkan, kecuali diisyaratkan secara lain oleh hukum atau perintah pengadilan.
                        </span>
                    </div>


                    <br/><br/>

                    <h2 className={"text-h2 text-xl desktop:text-2xl"}>
                        Pengungkapan Data
                    </h2>

                    <div class="value-span">
                        <span>
                            Kami tidak akan mengungkapkan, membagikan, menjual dan/atau menggunakan informasi pribadi Anda tanpa persetujuan atau instruksi Anda, kecuali kepada atau untuk keperluan dan kepentingan hukum.
                        </span>
                        <br/>
                        <span>
                            Kami dapat menyimpan dan memiliki hak untuk mengungkapkan informasi atau data mengenai Anda atau pengguna situs oleh Anda tanpa izin Anda terlebih dahulu apabila tindakan tersebut diperlukan untuk:
                        </span>

                        <ol className={"a"}>
                            <li class="li-privacy">melindungi dan membela hak, kepemilikan, dan keselamatan Kami, afiliasi Kami, para pengguna Situs atau publik;</li>
                            <li class="li-privacy">menerapkan syarat dan ketentuan yang berlaku dalam penggunaan Situs ini;</li>
                            <li class="li-privacy">menanggapi klaim yang menyatakan bahwa terdapat konten yang melanggar hak pihak ketiga lainnya;</li>
                            <li class="li-privacy">menanggapi klaim mengenai adanya aktivitas yang baik diduga maupun benar-benar melawan hukum;</li>
                            <li class="li-privacy">menanggapi audit atau menyelidiki keluhan atau ancaman keamanan; atau</li>
                            <li class="li-privacy">mematuhi hukum dan peraturan perundang-undangan yang berlaku, proses hukum dan/atau perintah pengadilan.</li>
                        </ol>
                    </div>


                    <br/><br/><br/>

                    <h2 className={"text-h2 text-xl desktop:text-2xl"}>
                        Perubahan Kebijakan Privasi
                    </h2>

                    <div class="value-span">
                        <span>
                            Kami memiliki hak untuk mengubah, memodifikasi, menambah atau menghapus bagian dari Kebijakan Privasi ini setiap saat.
                            Jika Anda terus menggunakan aplikasi HRUS Kami setelah adanya perubahan kebijakan privasi ini, berarti Anda menerima perubahan tersebut.
                        </span>
                    </div>
                </div>
                    
            </div>
            <Footer2/>
        </div>
        
    )
}

export default Privacy;